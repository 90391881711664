@import url('https://use.typekit.net/tsb5azc.css');

* {
  box-sizing: border-box;
}

html,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: hoss-round, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.rdw-editor-wrapper { 
  border: 1px solid black;
  border-radius: 4px;
}